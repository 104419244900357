import { Link } from 'gatsby'
import React from 'react'

export default function ElevateBlock() {
    return (
        <section className="has-background-dark has-text-centered">
            <div className="container has-background-light">
                <div className="columns">
                    <div className="column has-background-dark"></div>
                    <div className="column is-8">
                        <p className="title">Elevate Your Leadership with Top Talent</p>
                        <p className="pb-5 has-text-weight-bold">Partner with Top Talent to enhance success through coaching, leadership development, and talent and performance management.</p>
                        <a href="https://appointmentthing.com/judymchugh" className="button has-background-warning mx-3">Schedule your <span className="has-text-weight-bold px-1"> FREE </span> consultation</a>
                        <Link to="/contact" className="button has-text-dark has-background-warning px-3">Contact Judy</Link>
                    </div>
                    <div className="column has-background-dark"></div>
                </div>
            </div>
        </section>
    )
}
