import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/SEO'

export default function NotFound() {
    return (
        <Layout>
            <SEO />
            <div className="container has-text-centered columns">
                <div className="column"></div>
                <div className="column is-half">
                    <h1 className="title has-text-primary">That page does not exist...</h1>
                    <p className="subtitle has-text-primary">Let's get you back on your way to the top!</p>
                    <Link to="/" className="button has-background-primary">Back to Top Talent</Link>
                    <StaticImage 
                        src="../images/TopTalentLogo-green.png" 
                        alt="Top Talent and pyramid logo" 
                        width={400}
                    />
                </div>
            <div className="column"></div>
            </div>
        </Layout>
    )
}
