import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ElevateBlock from './ElevateBlock'
import "../pages/mystyles.scss"
import { Helmet } from 'react-helmet'

export default function Layout({ children }) {
    return (
        <div>
            <Helmet key="app-head">
                <html lang="en" />
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0" width="device-width" />
            </Helmet>   
            <Navbar />
            <div className="has-background-dark">
                { children }
            </div>
            <ElevateBlock />
            <Footer />
        </div>
    )
}
